.btnPrimaryOne {
  width: 230px;
  background-color: #4dbd74;
  color: #fff;
  border: none;
  min-height: 35px;
  border-radius: 5px;
}

.btnPrimaryTwo {
  width: 230px;
  background-color: #2f353a;
  color: #fff;
  border: none;
  min-height: 35px;
  border-radius: 5px;
}

.btnWrap {
  display: flex;
  gap: 15px;
}

.wrapHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.tableOne{
  font-weight: bold !important;
}
.modal{
  background: #24242583 !important;
}

/* https://www.youtube.com/watch?v=n4H7hT3d79c */

/* body {
  margin: 0;
  padding: 0;
  background:#3498db;
} */

.cs-loader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background:#fff;
}

.cs-loader-inner {
  transform: translateY(-50%);
  top: 50%;
  position: absolute;
  width: calc(100% - 200px);
  color: #000;
  padding: 0 100px;
  text-align: center;
}

.cs-loader-inner label {
  font-size: 20px;
  opacity: 0;
  display:inline-block;
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}

@-webkit-keyframes lol {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  66% {
    opacity: 1;
    -webkit-transform: translateX(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(300px);
  }
}

.cs-loader-inner label:nth-child(6) {
  -webkit-animation: lol 3s infinite ease-in-out;
  animation: lol 3s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
  -webkit-animation: lol 3s 100ms infinite ease-in-out;
  animation: lol 3s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
  -webkit-animation: lol 3s 200ms infinite ease-in-out;
  animation: lol 3s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
  -webkit-animation: lol 3s 300ms infinite ease-in-out;
  animation: lol 3s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
  -webkit-animation: lol 3s 400ms infinite ease-in-out;
  animation: lol 3s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
  -webkit-animation: lol 3s 500ms infinite ease-in-out;
  animation: lol 3s 500ms infinite ease-in-out;
}

.wrapMarking{
  display: flex;
  gap: 60px;
  border-bottom: 1px solid #F0F3F5;
}

.formGoupOne{
  width: 400px;
}

.formgroup input{
  margin-bottom: 40px;
  width: 400px;
}