// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";


.react-responsive-modal-root{
    z-index: 10000000000000000000000 !important;
}

.react-responsive-modal-closeButton{
    overflow: hidden !important;
}

.react-responsive-modal-modal{
    padding: 50px !important;
}


.listAAA {
    margin-top: unset !important;
    margin-bottom: unset !important;

    display: flex !important;
    align-items: flex-end !important;
    flex-wrap: wrap !important;
    list-style-type: none !important;
    gap: 5px !important;
  
    .page-item {
      outline: none !important;
      cursor: pointer !important;
      user-select: none !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      border: 1px solid #b3b3b3 !important;
      border-radius: 10% !important;
      width: 30px !important;
      height: 30px !important;
      overflow: hidden !important;
      .selected{
        border: unset !important;
      }
  
      &:last-child,
      &:first-child {
        .page-link {
        //   padding: 12px 15px 7px 15px;
          //  font-size: 22px;
        }
      }
  
      .page-link {
        outline: none;
        // padding: 10px 15px;
  
        &:hover,
        &:focus {
          color: #000;
          background-color: unset;
        }
      }
    }
  
    .page-item.selected {
      border: unset !important;
      .page-link {
        background-color: #008fbb !important;
        border: unset !important;
        border-radius: 10% !important;
        color: #fff !important;
        width: 100% !important;
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
      }
    }
  }

  .test{
    width: 700px !important;
  }